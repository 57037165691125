<template>
  <span data-cy="sampling">
    <h4 class="mt-40 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.sampling') }}</h4>
    <a-card :bordered="true" class="mb-22 sampling-method">
      <a-form layout="vertical">
        <a-row class="mb-20">
          <div class="flex">
            <a-form-item class="w270">
              <span slot="label" class="flex align-center">
                {{ $t('components.labels.samplingMethod') }}
                <a-tooltip
                  :title="$t('components.toolTips.samplingMethod')"
                  class="ml-8">
                  <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
                </a-tooltip>
              </span>
              <a-select v-model="sampling.samplingMethod"
                data-cy="sampling-method-dropdown"
                :get-popup-container="(triggerNode) => triggerNode.parentNode"
                @change="setDirty">
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option value="Cluster" data-cy="sampling-method-cluster-option" :disabled="isSupervisor">
                  {{ $tc('components.dropdown.cluster', 1) }}
                </a-select-option>
                <a-select-option value="Random" data-cy="sampling-method-random-option" :disabled="isSupervisor">
                  {{ $t('components.dropdown.random') }}
                </a-select-option>
                <a-select-option value="Exhaustive" data-cy="sampling-method-exhaustive-option" :disabled="isSupervisor">
                  {{ $t('components.dropdown.exhaustive') }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              v-if="sampling.samplingMethod === 'Cluster'"
              :label="$t('components.labels.secondStageSampling')"
              class="w270 ml-40 min-width-fit-content">
              <a-select
                v-model="sampling.secondStageSampling"
                data-cy="second-stage-sampling-method-dropdown"
                :get-popup-container="(triggerNode) => triggerNode.parentNode"
                @change="setDirty">
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option value="Simple Random" data-cy="second-stage-sampling-method-simple-random-option" :disabled="isSupervisor">
                  {{ $t('components.dropdown.simpleRandom') }}
                </a-select-option>
                <a-select-option value="Systematic Random" data-cy="second-stage-sampling-method-systematic-random-option" :disabled="isSupervisor">
                  {{ $t('components.dropdown.systematicRandom') }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </a-row>
        <a-form-item class="w635">
          <span slot="label" class="flex align-center">
           {{ $t('components.labels.householdDefinition') }}&nbsp;&nbsp;
            <a-tooltip class="ml-8">
              <div slot="title">
                {{ $t('components.toolTips.householdDefinition') }}
              </div>
              <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
            </a-tooltip>
          </span>
          <a-textarea
            v-model="sampling.householdDefinition"
            :auto-size="{ minRows: 2, maxRows: 6 }"
            :disabled="isSupervisor"
            @change="setDirty"/>
        </a-form-item>
      </a-form>
    </a-card>
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'MetadataSampling',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    onSave: {
      type: Function,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstLoad: true,
      sampling: {
        samplingMethod: 'Cluster',
        secondStageSampling: '',
        householdDefinition: ''
      }
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    sampling: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function() {
        if (this.sampling.samplingMethod !== 'Cluster') this.sampling.secondStageSampling = null;
        this.$emit('input', this.sampling);
        if (!this.firstLoad) this.onSave();
        this.firstLoad = false;
      }, 1000)
    }
  },
  mounted() {
    const { samplingMethod, secondStageSampling, householdDefinition } = this.metadata;
    this.sampling = {
      samplingMethod,
      secondStageSampling,
      householdDefinition
    };
  },
  methods: {
    ...mapActions(['setDirty'])
  }
};
</script>

<style lang="scss">
.sampling-method {
  .ant-form-vertical .ant-form-item {
    padding-bottom: 0;
    margin-bottom: unset;
  }
}
</style>
